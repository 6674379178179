// src/components/ResponseButtons.js
import React from 'react';
import { motion } from 'framer-motion';
import { FaUndo, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { getThemeColors } from '../styles/themes';

function ResponseButtons({ handleResponse, handleUndo, settings = {} }) {
  const themeColors = getThemeColors(settings.theme || 'ksiezniczkowy');

  const styles = {
    container: {
      position: 'fixed',
      bottom: '20%',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
      zIndex: 999,
    },
    buttonsRow: {
      display: 'flex',
      gap: '10px',
    },
    buttonGood: {
      backgroundColor: '#98FB98',
      color: '#006400',
      border: 'none',
      padding: '12px 24px',
      borderRadius: '25px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    buttonBad: {
      backgroundColor: '#FFB6C1',
      color: '#8B0000',
      border: 'none',
      padding: '12px 24px',
      borderRadius: '25px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    undoButton: {
      backgroundColor: '#f0f0f0',
      color: '#333',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '20px',
      cursor: 'pointer',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.buttonsRow}>
        <motion.button
          style={styles.buttonGood}
          onClick={() => handleResponse('Tak')}
          whileHover={{ 
            scale: 1.05,
            boxShadow: '0 5px 15px rgba(0,100,0,0.2)',
          }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          <FaThumbsUp style={{ marginRight: '8px' }} /> Dobrze
        </motion.button>
        <motion.button
          style={styles.buttonBad}
          onClick={() => handleResponse('Nie')}
          whileHover={{ 
            scale: 1.05,
            boxShadow: '0 5px 15px rgba(139,0,0,0.2)',
          }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          <FaThumbsDown style={{ marginRight: '8px' }} /> Źle
        </motion.button>
      </div>
      <motion.button
        style={styles.undoButton}
        onClick={handleUndo}
        whileHover={{ 
          scale: 1.05,
          boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
        }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.2 }}
      >
        <FaUndo style={{ marginRight: '5px' }} /> Cofnij odpowiedź
      </motion.button>
    </div>
  );
}

export default ResponseButtons;

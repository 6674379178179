// src/App.js
import React, { useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import ProfileManager from './components/ProfileManager';
import Login from './components/Login';
import Settings from './components/Settings';
import LetterDisplay from './components/LetterDisplay';
import ResponseButtons from './components/ResponseButtons';
import HistoryAndStats from './components/HistoryAndStats';
import LandingPage from './components/LandingPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getThemeColors } from './styles/themes';
import { 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  addDoc, 
  deleteDoc,
  query, 
  orderBy, 
  limit, 
  Timestamp 
} from 'firebase/firestore';
import ResetPassword from './components/ResetPassword';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

const defaultSettings = {
  type: 'duzeLitery',
  font: 'Arial',
  color: 'black',
  size: 100,
  order: 'los_popularne',
  theme: 'ksiezniczkowy',
};

const App = () => {
  const [user, setUser] = useState(null);
  const [showLandingPage, setShowLandingPage] = useState(true);
  const [isRegistering, setIsRegistering] = useState(false);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [profileSettings, setProfileSettings] = useState(null);
  const [currentCharacter, setCurrentCharacter] = useState('');
  const [charactersList, setCharactersList] = useState([]);
  const [index, setIndex] = useState(0);

  // Przykładowe litery i sylaby
  const letters = 'AĄBCĆDEĘFGHIJKLŁMNŃOÓPRSŚTUWXYZŹŻ'.split('');
  const syllables = [
    'ba', 'be', 'bi', 'bo', 'bu', 'by',
    'ca', 'ce', 'ci', 'co', 'cu', 'cy',
    'da', 'de', 'di', 'do', 'du', 'dy',
    'fa', 'fe', 'fi', 'fo', 'fu', 'fy',
    'ga', 'ge', 'gi', 'go', 'gu', 'gy',
    'ha', 'he', 'hi', 'ho', 'hu', 'hy',
    'ja', 'je', 'ji', 'jo', 'ju', 'jy',
    'ka', 'ke', 'ki', 'ko', 'ku', 'ky',
    'la', 'le', 'li', 'lo', 'lu', 'ly',
    'ma', 'me', 'mi', 'mo', 'mu', 'my',
    'na', 'ne', 'ni', 'no', 'nu', 'ny',
    'pa', 'pe', 'pi', 'po', 'pu', 'py',
    'ra', 're', 'ri', 'ro', 'ru', 'ry',
    'sa', 'se', 'si', 'so', 'su', 'sy',
    'ta', 'te', 'ti', 'to', 'tu', 'ty',
    'wa', 'we', 'wi', 'wo', 'wu', 'wy',
    'za', 'ze', 'zi', 'zo', 'zu', 'zy',
    'cha', 'che', 'chi', 'cho', 'chu', 'chy',
    'sza', 'sze', 'szi', 'szo', 'szu', 'szy',
    'dza', 'dze', 'dzi', 'dzo', 'dzu', 'dzy',
    'cza', 'cze', 'czi', 'czo', 'czu', 'czy',
    'nia', 'nie', 'nio', 'niu'
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("Zmiana stanu autoryzacji:", currentUser);
      setUser(currentUser);
      if (!currentUser) {
        setCurrentProfile(null);
        setProfileSettings(null);
        setShowLandingPage(true); // Pokazuj LandingPage po wylogowaniu
      } else {
        setShowLandingPage(false); // Ukrywaj LandingPage po zalogowaniu
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentProfile && user?.uid) {
      const fetchSettings = async () => {
        try {
          const profileDocRef = doc(db, 'users', user.uid, 'profiles', currentProfile.id);
          const profileDoc = await getDoc(profileDocRef);
          if (profileDoc.exists()) {
            setProfileSettings(profileDoc.data().settings);
          } else {
            console.warn("ProfileManager: Dokument profilu nie istnieje.");
            setProfileSettings(defaultSettings);
          }
        } catch (error) {
          console.error("App: Błąd podczas pobierania ustawień profilu:", error);
          toast.error("Błąd podczas pobierania ustawień profilu.");
          setProfileSettings(defaultSettings);
        }
      };
      fetchSettings();
    } else {
      setProfileSettings(null);
      setCurrentCharacter('');
      setCharactersList([]);
      setIndex(0);
    }
  }, [currentProfile, user]);

  // Nasłuchuj na 'type' i 'order'
  useEffect(() => {
    if (currentProfile && (profileSettings || defaultSettings)) {
      generateCharactersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProfile, profileSettings?.type, profileSettings?.order]);

  useEffect(() => {
    if (charactersList.length > 0 && index < charactersList.length) {
      setCurrentCharacter(charactersList[index]);
    }
  }, [index, charactersList]);

  const generateCharactersList = async () => {
    let list = [];

    const activeSettings = profileSettings || defaultSettings;

    if (activeSettings.type === 'sylaby') {
      list = [...syllables];
    } else {
      list = [...letters];
      if (activeSettings.type === 'maleLitery') {
        list = list.map(letter => letter.toLowerCase());
      } else if (activeSettings.type === 'mieszaneLitery') {
        list = list.map(letter => (Math.random() > 0.5 ? letter : letter.toLowerCase()));
      }
    }

    if (activeSettings.order === 'alfabetyczna') {
      list.sort((a, b) => a.localeCompare(b, 'pl'));
    } else if (activeSettings.order === 'los_popularne') {
      list = weightedShuffle(list);
    } else if (activeSettings.order === 'losowa') {
      list = shuffle(list);
    } else if (activeSettings.order === 'los_cwicz_bledy') {
      list = await errorWeightedShuffle(list);
    }

    setCharactersList(list);
    setIndex(0);
    console.log("Nowa charactersList wygenerowana:", list);
  };

  const weightedShuffle = (list) => {
    const frequency = {
      'A': 8.9, 'Ą': 0.6, 'B': 1.5, 'C': 3.3, 'Ć': 0.3, 'D': 3.3, 'E': 7.7, 'Ę': 0.5,
      'F': 0.2, 'G': 1.4, 'H': 1.2, 'I': 6.2, 'J': 2.8, 'K': 3.2, 'L': 2.4, 'Ł': 2.1,
      'M': 2.6, 'N': 4.0, 'Ń': 0.2, 'O': 5.8, 'Ó': 1.1, 'P': 2.5, 'R': 3.0, 'S': 3.4,
      'Ś': 0.5, 'T': 3.4, 'U': 1.9, 'W': 3.1, 'Y': 3.2, 'Z': 3.0, 'Ź': 0.2, 'Ż': 0.6,
      'X': 0.5, // Waga dla dużej litery 'X'
      'x': 0.5, // Waga dla małej litery 'x'
      'a': 8.9, 'ą': 0.6, 'b': 1.5, 'c': 3.3, 'ć': 0.3, 'd': 3.3, 'e': 7.7, 'ę': 0.5,
      'f': 0.2, 'g': 1.4, 'h': 1.2, 'i': 6.2, 'j': 2.8, 'k': 3.2, 'l': 2.4, 'ł': 2.1,
      'm': 2.6, 'n': 4.0, 'ń': 0.2, 'o': 5.8, 'ó': 1.1, 'p': 2.5, 'r': 3.0, 's': 3.4,
      'ś': 0.5, 't': 3.4, 'u': 1.9, 'w': 3.1, 'y': 3.2, 'z': 3.0, 'ź': 0.2, 'ż': 0.6,
      ...syllables.reduce((acc, syllable) => {
        acc[syllable] = 1.0;
        return acc;
      }, {})
    };

    let weightedList = [];
    list.forEach(item => {
      const weight = frequency[item] || 1;
      for (let i = 0; i < weight; i++) {
        weightedList.push(item);
      }
    });

    for (let i = weightedList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [weightedList[i], weightedList[j]] = [weightedList[j], weightedList[i]];
    }

    return weightedList;
  };

  const shuffle = (list) => {
    for (let i = list.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [list[i], list[j]] = [list[j], list[i]];
    }
    return list;
  };

  const errorWeightedShuffle = async (list) => {
    if (!user || !currentProfile) {
      return shuffle(list);
    }

    // Pobierz odpowiedzi użytkownika
    const responsesRef = collection(db, 'users', user.uid, 'profiles', currentProfile.id, 'responses');
    const q = query(responsesRef, orderBy('timestamp', 'desc'));
    const snapshot = await getDocs(q);

    const errorCounts = {};
    const timeWindow = 7 * 24 * 60 * 60 * 1000; // 7 dni w milisekundach
    const now = Date.now();

    snapshot.docs.forEach(doc => {
      const data = doc.data();
      const character = data.character;
      const isCorrect = data.isCorrect;
      const timestamp = data.timestamp.toDate().getTime();

      if (now - timestamp <= timeWindow) {
        if (!isCorrect) {
          errorCounts[character] = (errorCounts[character] || 0) + 1;
        }
      }
    });

    const maxErrors = Math.max(...Object.values(errorCounts), 1);

    const weights = list.map(item => {
      const errorCount = errorCounts[item] || 0;
      return 1 + (errorCount / maxErrors) * 5; // Możesz dostosować mnożnik
    });

    let weightedList = [];
    list.forEach((item, index) => {
      const weight = Math.floor(weights[index]);
      for (let i = 0; i < weight; i++) {
        weightedList.push(item);
      }
    });

    return shuffle(weightedList);
  };

  const handleResponse = async (response) => {
    console.log("handleResponse wywołana z odpowiedzią:", response);
    if (!user || !currentProfile) return;

    const isCorrect = response.toLowerCase() === 'tak';

    const responseData = {
      character: currentCharacter,
      settings: profileSettings || defaultSettings,
      isCorrect: isCorrect,
      timestamp: Timestamp.fromDate(new Date()),
    };

    try {
      const responsesCollectionRef = collection(
        db,
        "users",
        user.uid,
        "profiles",
        currentProfile.id,
        "responses"
      );
      await addDoc(responsesCollectionRef, responseData);
      console.log("Odpowiedź zapisana w Firebase");
      // Usunięto toast.success("Odpowiedź zapisana pomyślnie!");
    } catch (error) {
      console.error("Błąd podczas zapisywania odpowiedzi:", error);
      toast.error("Błąd podczas zapisywania odpowiedzi."); // Zostawiamy powiadomienie o błędzie
    }

    setIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      console.log(`Aktualizacja index z ${prevIndex} na ${newIndex}`);
      if (newIndex < charactersList.length) {
        return newIndex;
      } else {
        generateCharactersList();
        return 0;
      }
    });
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setCurrentProfile(null);
        toast.info("Wylogowano pomyślnie.");
      })
      .catch((error) => {
        console.error("Błąd podczas wylogowywania:", error);
        toast.error("Błąd podczas wylogowywania.");
      });
  };

  const handleSwitchProfile = () => {
    setCurrentProfile(null);
  };

  const themeColors = getThemeColors(profileSettings?.theme || defaultSettings.theme || 'ksiezniczkowy');

  const appStyles = {
    display: 'flex',
    height: '100vh',
    fontFamily: 'sans-serif',
    backgroundColor: themeColors.main,
    color: themeColors.text,
    transition: 'background-color 0.3s ease, color 0.3s ease',
    position: 'relative',
  };

  const handleUndo = async () => {
    if (!user || !currentProfile || index <= 0) return;

    try {
      // Pobierz ostatnią odpowiedź
      const responsesRef = collection(db, "users", user.uid, "profiles", currentProfile.id, "responses");
      const q = query(responsesRef, orderBy("timestamp", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        // Usuń ostatnią odpowiedź
        await deleteDoc(doc(responsesRef, querySnapshot.docs[0].id));
        
        // Cofnij index
        setIndex(prevIndex => Math.max(0, prevIndex - 1));
      }
    } catch (error) {
      console.error("Błąd podczas cofania odpowiedzi:", error);
    }
  };

  return (
    <Router>
      <div style={appStyles}>
        <ToastContainer />
        <Routes>
          <Route path="/reset-password" element={
            !user ? <ResetPassword /> : <Navigate to="/" />
          } />
          <Route path="/" element={
            !user ? (
              showLandingPage ? (
                <LandingPage 
                  onLoginClick={() => setShowLandingPage(false)} 
                  onRegisterClick={() => {
                    setShowLandingPage(false);
                    setIsRegistering(true);
                  }}
                  theme={defaultSettings.theme}
                />
              ) : (
                <Login isRegistering={isRegistering} setIsRegistering={setIsRegistering} />
              )
            ) : !currentProfile ? (
              <ProfileManager 
                currentUser={user} 
                setCurrentProfile={setCurrentProfile} 
                settings={profileSettings || defaultSettings}
                handleLogout={handleLogout}
              />
            ) : (
              <>
                <Settings
                  settings={profileSettings || defaultSettings}
                  setSettings={setProfileSettings}
                  currentUser={user}
                  currentProfile={currentProfile}
                  setCurrentProfile={setCurrentProfile}
                  handleLogout={handleLogout}
                  handleSwitchProfile={() => setCurrentProfile(null)}
                  currentCharacter={currentCharacter}
                />
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <LetterDisplay
                    character={currentCharacter}
                    settings={profileSettings || defaultSettings}
                  />
                  <ResponseButtons 
                    handleResponse={handleResponse} 
                    handleUndo={handleUndo}
                    settings={profileSettings || defaultSettings}
                  />
                </div>
                <HistoryAndStats
                  currentUser={user}
                  currentProfile={currentProfile}
                  settings={profileSettings || defaultSettings}
                  currentExerciseType={profileSettings?.type || defaultSettings.type}
                />
              </>
            )
          } />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

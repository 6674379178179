// src/components/ProfileManager.js
import React, { useState, useEffect } from 'react';
import { 
  collection, 
  onSnapshot, 
  addDoc, 
  doc, 
  getDocs, 
  writeBatch 
} from 'firebase/firestore';
import { db, auth } from '../firebase';
import { toast } from 'react-toastify';
import { signOut } from 'firebase/auth';
import { getThemeColors } from '../styles/themes';
import { motion } from 'framer-motion';
import { FaUserPlus, FaTrash, FaUser } from 'react-icons/fa';

const defaultSettings = {
  type: 'duzeLitery', // duzeLitery, maleLitery, mieszaneLitery, sylaby
  font: 'Arial',
  color: 'black',
  size: 100,
  order: 'losowa', // alfabetyczna, losowa
  theme: 'ksiezniczkowy',
};

function ProfileManager({ settings = {}, currentUser, setCurrentProfile, handleLogout }) {
  const [profiles, setProfiles] = useState([]);
  const [newProfileName, setNewProfileName] = useState('');

  const themeColors = getThemeColors(settings?.theme || 'ksiezniczkowy');

  const styles = {
    container: {
      backgroundColor: '#FFFFFF', // Ustawienie białego tła niezależnie od motywu
      color: '#000000', // Ciemny tekst dla czytelności
      padding: '40px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      margin: 'auto', // Centrowanie kontenera
    },
    input: {
      backgroundColor: '#f0f0f0', // Jasnoszare tło dla pola input
      color: '#000000',
      border: '1px solid #ccc',
      padding: '10px',
      marginBottom: '15px',
      borderRadius: '4px',
      width: '100%',
      boxSizing: 'border-box',
    },
    button: {
      backgroundColor: '#007BFF', // Nowoczesny niebieski kolor
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    
    // Dodane style:
    profileList: {
      listStyleType: 'none',
      padding: 0,
      marginBottom: '20px',
    },
    profileItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    profileButton: {
      flex: 1,
      backgroundColor: '#008CBA',
      color: '#fff',
      border: 'none',
      padding: '10px 15px',
      borderRadius: '20px',
      cursor: 'pointer',
      marginRight: '10px',
    },
    deleteButton: {
      backgroundColor: '#f44336',
      color: '#fff',
      border: 'none',
      padding: '10px 15px',
      borderRadius: '20px',
      cursor: 'pointer',
    },
    addProfileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    addButton: {
      backgroundColor: '#008CBA',
      color: '#fff',
      border: 'none',
      padding: '10px',
      borderRadius: '20px',
      cursor: 'pointer',
      width: '100%',
      marginTop: '10px',
    },
    logoutButton: {
      backgroundColor: '#DC3545', // Czerwony kolor dla przycisku "Wyloguj się"
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      marginTop: '20px',
      width: '100%',
    },
  };

  useEffect(() => {
    if (!currentUser) {
      console.log("ProfileManager: Brak zalogowanego użytkownika.");
      return;
    }

    console.log("ProfileManager: Pobieranie profili dla użytkownika:", currentUser.uid);
    const profilesCollection = collection(db, 'users', currentUser.uid, 'profiles');

    const unsubscribe = onSnapshot(
      profilesCollection,
      (snapshot) => {
        const profilesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("ProfileManager: Pobrane profile:", profilesList);
        setProfiles(profilesList);
      },
      (error) => {
        console.error("ProfileManager: Błąd podczas pobierania profili:", error);
        toast.error("Błąd podczas pobierania profili.");
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  const handleAddProfile = async () => {
    const trimmedName = newProfileName.trim();
    if (!trimmedName) {
      toast.warn("Nazwa profilu nie może być pusta.");
      return;
    }

    try {
      const profilesCollection = collection(db, 'users', currentUser.uid, 'profiles');
      await addDoc(profilesCollection, { name: trimmedName, settings: defaultSettings });
      toast.success("Profil dodany pomyślnie!");
      setNewProfileName('');
    } catch (error) {
      console.error("ProfileManager: Błąd podczas dodawania profilu:", error);
      toast.error("Błąd podczas dodawania profilu.");
    }
  };

  const handleDeleteProfile = async (profileId, profileName) => {
    if (!window.confirm(`Czy na pewno chcesz usunąć profil "${profileName}"?`)) {
      return;
    }

    console.log(`Próba usunięcia profilu: ID=${profileId}, Nazwa=${profileName}, Użytkownik=${currentUser.uid}`);

    try {
      // Referencja do profilu
      const profileDocRef = doc(db, 'users', currentUser.uid, 'profiles', profileId);

      // Pobierz wszystkie odpowiedzi w podkolekcji 'responses'
      const responsesCollectionRef = collection(profileDocRef, 'responses');
      const responsesSnapshot = await getDocs(responsesCollectionRef);

      console.log(`Znaleziono ${responsesSnapshot.size} odpowiedzi do usunięcia.`);

      // Inicjalizacja batched write
      const batch = writeBatch(db);

      // Dodaj wszystkie operacje usuwania odpowiedzi do batched write
      responsesSnapshot.forEach(responseDoc => {
        batch.delete(responseDoc.ref);
      });

      // Dodaj operację usunięcia samego profilu do batched write
      batch.delete(profileDocRef);

      // Commit batched write
      await batch.commit();

      console.log(`Profil "${profileName}" oraz ${responsesSnapshot.size} odpowiedzi zostały usunięte.`);
      toast.success(`Profil "${profileName}" oraz jego odpowiedzi zostały usunięte.`);
    } catch (error) {
      console.error("ProfileManager: Błąd podczas usuwania profilu:", error);
      toast.error(`Błąd podczas usuwania profilu: ${error.message}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddProfile();
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>
        <FaUser style={{ marginRight: '8px' }} />
        Wybierz Profil
      </h2>
      <ul style={styles.profileList}>
        {profiles.map(profile => (
          <li key={profile.id} style={styles.profileItem}>
            <motion.button
              onClick={() => setCurrentProfile(profile)}
              style={styles.profileButton}
              whileHover={{ scale: 1.02, boxShadow: '0 5px 15px rgba(0,139,209,0.2)' }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.2 }}
            >
              <FaUser style={{ marginRight: '8px' }} /> {profile.name}
            </motion.button>
            <motion.button
              onClick={() => handleDeleteProfile(profile.id, profile.name)}
              style={styles.deleteButton}
              whileHover={{ scale: 1.02, boxShadow: '0 5px 15px rgba(244,67,54,0.2)' }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.2 }}
            >
              <FaTrash />
            </motion.button>
          </li>
        ))}
      </ul>
      <div style={styles.addProfileContainer}>
        <input
          type="text"
          value={newProfileName}
          onChange={(e) => setNewProfileName(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Wpisz nazwę nowego profilu"
          style={styles.input}
        />
        <motion.button 
          onClick={handleAddProfile} 
          style={styles.addButton}
          whileHover={{ scale: 1.05, boxShadow: '0 5px 15px rgba(0,139,209,0.2)' }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          <FaUserPlus style={{ marginRight: '8px' }} /> Dodaj Profil
        </motion.button>
      </div>
      <motion.button 
        onClick={handleLogout} 
        style={{
          ...styles.button,
          backgroundColor: themeColors.error,
          color: '#fff',
          marginTop: '20px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px'
        }}
        whileHover={{ scale: 1.05, boxShadow: '0 5px 15px rgba(244,67,54,0.2)' }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.2 }}
      >
        <FaUser /> Wyloguj się
      </motion.button>
    </div>
  );
}

export default ProfileManager;

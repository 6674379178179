// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { auth, googleProvider, facebookProvider } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { FaGoogle, FaBookOpen, FaFacebook } from 'react-icons/fa';
import '@fontsource/poppins';
import { getThemeColors } from '../styles/themes';
import { Link } from 'react-router-dom';

const Login = ({ isRegistering, setIsRegistering }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const theme = 'ksiezniczkowy';
  const themeColors = getThemeColors(theme);

  const styles = {
    mainContainer: {
      display: 'flex',
      width: '100%',
      height: '100vh',
      background: `linear-gradient(135deg, ${themeColors.main}, ${themeColors.panel})`,
      overflow: 'hidden',
      fontFamily: 'Poppins, sans-serif',
      justifyContent: 'center',
      alignItems: 'center',
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '40px',
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      borderRadius: '16px',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
      maxWidth: '400px',
      width: '100%',
    },
    title: {
      fontSize: '2.5rem',
      color: '#2c3e50',
      marginBottom: '20px',
      textAlign: 'center',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    subtitle: {
      fontSize: '1.5rem',
      color: '#34495e',
      marginBottom: '30px',
      textAlign: 'center',
    },
    input: {
      padding: '12px',
      borderRadius: '8px',
      border: '1px solid #ccc',
      fontSize: '16px',
      width: '100%',
      boxSizing: 'border-box',
      marginBottom: '15px',
    },
    button: {
      padding: '12px',
      borderRadius: '8px',
      border: 'none',
      backgroundColor: themeColors.button,
      color: themeColors.buttonText,
      fontSize: '16px',
      cursor: 'pointer',
      transition: 'background-color 0.3s, transform 0.2s',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      marginTop: '10px',
    },
    googleButton: {
      padding: '12px',
      borderRadius: '8px',
      border: 'none',
      backgroundColor: '#DB4437',
      color: '#fff',
      fontSize: '16px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
      transition: 'background-color 0.3s, transform 0.2s',
      width: '100%',
    },
    divider: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      margin: '15px 0',
      width: '100%',
    },
    dividerLine: {
      flex: 1,
      borderBottom: '1px solid #ccc',
    },
    dividerText: {
      padding: '0 10px',
      color: '#666',
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
    },
    switchButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: themeColors.button,
      cursor: 'pointer',
      marginTop: '15px',
      fontSize: '14px',
      textDecoration: 'underline',
    },
    facebookButton: {
      padding: '12px',
      borderRadius: '8px',
      border: 'none',
      backgroundColor: '#4267B2',
      color: '#fff',
      fontSize: '16px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
      transition: 'background-color 0.3s, transform 0.2s',
      width: '100%',
      marginTop: '10px',
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isRegistering) {
        await createUserWithEmailAndPassword(auth, email, password);
        toast.success('Konto zostało utworzone pomyślnie!');
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        toast.success('Zalogowano pomyślnie!');
      }
    } catch (error) {
      console.error('Błąd:', error);
      let errorMessage = 'Wystąpił błąd podczas ' + (isRegistering ? 'rejestracji' : 'logowania');
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'Ten email jest już używany';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Nieprawidłowy adres email';
          break;
        case 'auth/weak-password':
          errorMessage = 'Hasło jest za słabe';
          break;
        case 'auth/user-not-found':
          errorMessage = 'Nie znaleziono użytkownika o podanym emailu';
          break;
        case 'auth/wrong-password':
          errorMessage = 'Nieprawidłowe hasło';
          break;
        default:
          errorMessage = 'Wystąpił nieoczekiwany błąd';
      }
      toast.error(errorMessage);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signOut(auth);
      const result = await signInWithPopup(auth, googleProvider);
      console.log("Pomyślnie zalogowano przez Google:", result.user);
      toast.success('Zalogowano pomyślnie przez Google!');
    } catch (error) {
      console.error('Błąd logowania przez Google:', error);
      let errorMessage = 'Wystąpił błąd podczas logowania przez Google';
      handleAuthError(error, errorMessage);
    }
  };

  const handleFacebookLogin = async () => {
    try {
      await signOut(auth);
      const result = await signInWithPopup(auth, facebookProvider);
      console.log("Pomyślnie zalogowano przez Facebook:", result.user);
      toast.success('Zalogowano pomyślnie przez Facebook!');
    } catch (error) {
      console.error('Błąd logowania przez Facebook:', error);
      let errorMessage = 'Wystąpił błąd podczas logowania przez Facebook';
      handleAuthError(error, errorMessage);
    }
  };

  const handleAuthError = (error, defaultMessage) => {
    let errorMessage = defaultMessage;
    switch (error.code) {
      case 'auth/popup-blocked':
        errorMessage = 'Wyskakujące okienko zostało zablokowane. Proszę zezwolić na wyskakujące okienka dla tej strony.';
        break;
      case 'auth/popup-closed-by-user':
        errorMessage = 'Okno logowania zostało zamknięte przed ukończeniem procesu.';
        break;
      case 'auth/cancelled-popup-request':
        errorMessage = 'Poprzednia próba logowania jest w toku. Proszę spróbować ponownie za chwilę.';
        break;
      case 'auth/account-exists-with-different-credential':
        errorMessage = 'Konto z tym adresem email już istnieje z inną metodą logowania.';
        break;
      default:
        errorMessage = `Błąd logowania: ${error.message}`;
    }
    toast.error(errorMessage);
  };

  return (
    <div style={styles.mainContainer}>
      <motion.div 
        style={styles.formContainer}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h1 style={styles.title}><FaBookOpen /> Literki i Sylabki</h1>
        <h2 style={styles.subtitle}>{isRegistering ? 'Rejestracja' : 'Logowanie'}</h2>
        
        <motion.button
          type="button"
          style={styles.googleButton}
          onClick={handleGoogleLogin}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <FaGoogle /> Kontynuuj z Google
        </motion.button>

        <motion.button
          type="button"
          style={styles.facebookButton}
          onClick={handleFacebookLogin}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <FaFacebook /> Kontynuuj z Facebook
        </motion.button>

        <div style={styles.divider}>
          <div style={styles.dividerLine}></div>
          <div style={styles.dividerText}>lub</div>
          <div style={styles.dividerLine}></div>
        </div>
        <div style={{ textAlign: 'center', color: '#666', marginBottom: '10px', fontSize: '14px' }}>
          kontynuuj przez adres e-mail
        </div>

        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
            required
          />
          <input
            type="password"
            placeholder="Hasło"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
            required
          />
          <motion.button
            type="submit"
            style={styles.button}
            whileHover={{ backgroundColor: isRegistering ? '#28a745' : '#1E90FF' }}
            whileTap={{ scale: 0.98 }}
          >
            {isRegistering ? 'Zarejestruj się' : 'Zaloguj się'}
          </motion.button>
          
          {!isRegistering && (
            <Link 
              to="/reset-password" 
              className="forgot-password-link"
              style={{
                display: 'block',
                marginTop: '10px',
                textAlign: 'center',
                color: '#FF69B4',
                textDecoration: 'none',
                fontSize: '0.9rem'
              }}
            >
              Zapomniałeś hasła?
            </Link>
          )}
        </form>

        <button
          type="button"
          onClick={() => setIsRegistering(!isRegistering)}
          style={styles.switchButton}
        >
          {isRegistering ? 'Masz już konto? Zaloguj się' : 'Nie masz konta? Zarejestruj się'}
        </button>
      </motion.div>
    </div>
  );
};

export default Login;

/*
Plik: ResetPassword.js
Cel: Obsługa procesu resetowania hasła użytkownika
Funkcjonalności:
- Formularz do wprowadzenia adresu email
- Wysyłanie maila z linkiem do resetowania hasła
- Obsługa błędów i komunikatów
- Możliwość powrotu do strony logowania
*/

import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Link do resetowania hasła został wysłany na podany adres email.');
      setError('');
    } catch (error) {
      setError(
        error.code === 'auth/user-not-found' 
          ? 'Nie znaleziono użytkownika o podanym adresie email.'
          : 'Wystąpił błąd podczas wysyłania linku resetującego hasło.'
      );
      setMessage('');
    }
  };

  return (
    <div className="reset-password-container" style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '40px',
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      borderRadius: '16px',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
      maxWidth: '400px',
      width: '100%',
      margin: '0 auto',
      marginTop: '50px'
    }}>
      <h2 style={{ marginBottom: '20px', color: '#2c3e50' }}>Resetuj hasło</h2>
      <form onSubmit={handleResetPassword} style={{ width: '100%' }}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Podaj adres email"
          required
          style={{
            width: '100%',
            padding: '12px',
            marginBottom: '15px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            fontSize: '16px',
            boxSizing: 'border-box'
          }}
        />
        <button 
          type="submit"
          style={{
            width: '100%',
            padding: '12px',
            backgroundColor: '#FF69B4',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            fontSize: '16px',
            cursor: 'pointer',
            marginBottom: '15px'
          }}
        >
          Wyślij link resetujący
        </button>
      </form>
      {message && <p className="success-message" style={{ color: '#28a745', marginBottom: '15px' }}>{message}</p>}
      {error && <p className="error-message" style={{ color: '#dc3545', marginBottom: '15px' }}>{error}</p>}
      <button 
        onClick={() => navigate('/')}
        style={{
          marginTop: '20px',
          padding: '10px 20px',
          backgroundColor: 'transparent',
          border: '1px solid #FF69B4',
          color: '#FF69B4',
          borderRadius: '8px',
          cursor: 'pointer',
          transition: 'all 0.3s ease'
        }}
      >
        Powrót do logowania
      </button>
    </div>
  );
};

export default ResetPassword; 
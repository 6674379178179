// src/components/LandingPage.js
import React from 'react';
import { motion } from 'framer-motion';
import { FaSignInAlt } from 'react-icons/fa';

const LandingPage = ({ onLoginClick }) => {
  const styles = {
    fullPage: {
      height: '100vh',
      width: '100vw',
      background: 'linear-gradient(135deg, #5CC7F7, #2980B9)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#333',
      textAlign: 'center',
      padding: '20px',
      transition: 'background-color 0.3s, color 0.3s',
    },
    title: {
      fontSize: '4rem',
      marginBottom: '20px',
      fontWeight: 'bold',
      background: 'linear-gradient(90deg, #FF69B4, #FFB6C1)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    },
    description: {
      fontSize: '1.2rem',
      marginBottom: '40px',
      maxWidth: '600px',
      lineHeight: '1.6',
      color: '#333',
    },
    buttonContainer: {
      display: 'flex',
      gap: '20px',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      padding: '12px 24px',
      fontSize: '1rem',
      borderRadius: '25px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s, transform 0.2s',
      boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
      backgroundColor: '#FF69B4',
      color: '#fff',
    },
  };

  return (
    <div style={styles.fullPage}>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        style={styles.container}
      >
        <motion.h1
          style={styles.title}
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.6, ease: 'easeInOut' }}
        >
          Literki i Sylabki
        </motion.h1>
        <p style={styles.description}>
          <strong>Odkryj radość wspólnego czytania razem z Twoim dzieckiem!</strong> Nasza interaktywna aplikacja stworzona jest z myślą o małych uczniach, którzy dopiero wkraczają w świat liter i sylab. Dzięki angażującym ćwiczeniom Twoje dziecko nie tylko nauczy się podstaw czytania, ale także rozwinie swoje zdolności językowe poprzez zabawę. Nasze metody to połączenie nauki i dobrej zabawy, które wspomagają naturalny rozwój maluchów. Dołącz do tysięcy rodziców, którzy już zaufały aplikacji <em>Literki i Sylabki</em> i wspólnie zacznijcie tę fascynującą przygodę już dziś!
        </p>
        <div style={styles.buttonContainer}>
          <motion.button
            style={styles.button}
            onClick={onLoginClick}
            whileHover={{ scale: 1.05, backgroundColor: '#FF4FA7' }}
            whileTap={{ scale: 0.95 }}
            aria-label="Zaloguj lub zarejestruj się"
          >
            <FaSignInAlt /> Zaloguj lub zarejestruj się
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default LandingPage;
